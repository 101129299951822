


















































































































































































import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import accountService from '../../services/account-service';
const moment = require('moment')
import { VueTreeList, Tree, } from 'vue-tree-list'

@Component({
  components: { Header, VueTreeList },
})
export default class ChartsOfAccounts extends Vue {
  public filter = null;
  public currentItem = null;
  public currentIndex = -1;
  public currentPage = 1;
  public title = "";
  public perpage = 100;
  search_code = '';
  public option_group: any = [];
  option_accounts: any = [];
  obj_params: any = {};
  selected_item_coa: any = {};
  params_parent_code: any = {};
  searchTerm = '';
  datatre = new Tree([
    {
      name: 'Balance Sheet',
      id: 1,
      pid: 0,
      dragDisabled: true,
      addTreeNodeDisabled: true,
      addLeafNodeDisabled: true,
      editNodeDisabled: true,
      delNodeDisabled: true,
      children: [
        {
          name: 'Assets',
          id: 3,
          isLeaf: false,
          pid: 1,
          dragDisabled: true,
          addTreeNodeDisabled: true,
          addLeafNodeDisabled: true,
          editNodeDisabled: true,
          delNodeDisabled: true,
        },
        {
          name: 'Liabilities',
          id: 4,
          pid: 1,
          dragDisabled: true,
          addTreeNodeDisabled: true,
          addLeafNodeDisabled: true,
          editNodeDisabled: true,
          delNodeDisabled: true,
        },
        {
          name: 'Equity',
          id: 5,
          pid: 1,
          dragDisabled: true,
          addTreeNodeDisabled: true,
          addLeafNodeDisabled: true,
          editNodeDisabled: true,
          delNodeDisabled: true,
        }

      ]
    },
    {
      name: '	Profit & Loss',
      id: 2,
      pid: 0,
      dragDisabled: true,
      addTreeNodeDisabled: true,
      addLeafNodeDisabled: true,
      editNodeDisabled: true,
      delNodeDisabled: true,
      children: [{
        name: 'Expenses',
        id: 6,
        pid: 2,
        dragDisabled: true,
        addTreeNodeDisabled: true,
        addLeafNodeDisabled: true,
        editNodeDisabled: true,
        delNodeDisabled: true,
      },
      {
        name: 'Revenue',
        id: 7,
        pid: 2,
        dragDisabled: true,
        addTreeNodeDisabled: true,
        addLeafNodeDisabled: true,
        editNodeDisabled: true,
        delNodeDisabled: true,
      }]
    },
  ]);

  public fields = [
    // {
    //   key: "id",
    //   label: "#",
    // },
    {
      key: "code",
    },
    {
      key: "title",
    },
    {
      key: "parent_title",
      label: "Parent"
    },
    {
      key: "group",
    },
    {

      key: "node_type",
      label: "Type"
    },
    {
      key: "created_at",
      label: "Date",
      formatter: (value: any) => {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
  ];
  public columns = [
    {
      field: "code",
      label: "Code",
    },
    {
      field: "title",
      label: "Title",
    },
    {
      field: "parent_title",
      label: "Parent",
    },
    {
      field: "group",
      label: "Group"
    },
    {

      field: "node_type",
      label: "Type"
    },
    {
      label: 'date',
      field: 'created_at',
      dataFormat: (value: any) => {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    }

  ];

  get rows() {
    return this.selected_items.length;
  }
  public selected_items = [
    {
      id: "",
      title: "",
      code: "",
      parent_title: "",
      group: "",
      node_type: "",
      created_at: "",
    },
  ];

  onRowClicked(item: any, index: any, event: any) {
    this.selected_item_coa = item
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-show");
  }

  retrieve(id: any) {
    accountService.getByParent(id)
      .then((response) => {
        this.selected_items = response.data.result;
      })
      .catch((e) => {
        console.log(e);
      });

    accountService.getAccountGroup()
      .then((response) => {
        let group = response.data;
        this.option_group = group.map((item: any) => { return { value: item.title, text: item.title }; });
        // this.option_group.push({ value: 0, text: "Please select an option" });        
      })
      .catch((e) => {
        console.log(e);
      });



  }
  onClick(params: any) {
    this.retrieve(params.id)
    this.datatre = new Tree([
      {
        name: 'Balance Sheet',
        id: 1,
        pid: 0,
        dragDisabled: true,
        addTreeNodeDisabled: true,
        addLeafNodeDisabled: true,
        editNodeDisabled: true,
        delNodeDisabled: true,
        children: [
          {
            name: 'Assets',
            id: 3,
            isLeaf: false,
            pid: 1,
            dragDisabled: true,
            addTreeNodeDisabled: true,
            addLeafNodeDisabled: true,
            editNodeDisabled: true,
            delNodeDisabled: true,
          },
          {
            name: 'Liabilities',
            id: 4,
            pid: 1,
            dragDisabled: true,
            addTreeNodeDisabled: true,
            addLeafNodeDisabled: true,
            editNodeDisabled: true,
            delNodeDisabled: true,
          },
          {
            name: 'Equity',
            id: 5,
            pid: 1,
            dragDisabled: true,
            addTreeNodeDisabled: true,
            addLeafNodeDisabled: true,
            editNodeDisabled: true,
            delNodeDisabled: true,
          }

        ]
      },
      {
        name: '	Profit & Loss',
        id: 2,
        pid: 0,
        dragDisabled: true,
        addTreeNodeDisabled: true,
        addLeafNodeDisabled: true,
        editNodeDisabled: true,
        delNodeDisabled: true,
        children: [{
          name: 'Expenses',
          id: 6,
          pid: 2,
          dragDisabled: true,
          addTreeNodeDisabled: true,
          addLeafNodeDisabled: true,
          editNodeDisabled: true,
          delNodeDisabled: true,
        },
        {
          name: 'Revenue',
          id: 7,
          pid: 2,
          dragDisabled: true,
          addTreeNodeDisabled: true,
          addLeafNodeDisabled: true,
          editNodeDisabled: true,
          delNodeDisabled: true,
        }]
      },
    ]);
  }

  create() {

    this.obj_params['parent_code'] = this.params_parent_code.id
    this.obj_params['parent_title'] = this.params_parent_code.title
    if (this.obj_params.id) {

      accountService.updateAccount(this.obj_params)
        .then((response) => {
          this.retrieve(1);
          this.obj_params = {};
          this.option_accounts = [];
          this.params_parent_code = {};
        })
        .catch((e) => {
          console.log(e);
        });
      this.$root.$emit("bv::toggle::collapse", "sidebar-variant-show");
    } else {
      accountService.createAccount(this.obj_params)
        .then((response) => {
          this.retrieve(1);
          this.obj_params = {};
          this.option_accounts = [];
          this.params_parent_code = {};
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  search_parent_account(title: any) {
    if (title.length > 4) {
      accountService.getByTitle(title)
        .then((response) => {
          this.option_accounts = response.data.result;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.option_accounts = [];
    }

  }

  get_parent_account(data: any) {
    this.params_parent_code = data;
    if (this.params_parent_code) {
      this.option_accounts = [];
    }
  }

  edit_account(data: any) {
    this.obj_params = data
    this.params_parent_code.id = data['parent_code_id']
    this.params_parent_code.title = data['parent_title']
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-create");
  }

  search_code_method(code: any) {
    accountService.getByTitleMaster(code)
      .then((response) => {
        this.selected_items = response.data.result;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  reset_search(code: any) {
    this.retrieve(1);
    this.search_code = '';
  }

  mounted() {
    this.retrieve(1);
  }

  reset() {
    this.obj_params = {};
    this.option_accounts = [];
    this.params_parent_code = {};
  }

}
